import React from 'react'
import { Link } from 'gatsby'
import tachyons from 'tachyons-components'
import Layout from '../../components/Layout'
import Helmet from 'react-helmet'
import GlobalStyle from "../../styles/GlobalStyle"

const PageWrapper = tachyons(`div`)`
pv3
`
const PageSection = tachyons(`section`)`
dt wrap pt5 pt6-ns pb5
`
const PageHeader = tachyons(`h1`)`
f3 fw6 mb2
`
const ButtonSecondary = tachyons(Link)`
button w-100 w-auto-l no-underline br2 ph4 pv3 dib tc bg-secondary bs-secondary br-secondary mt3 
`

export default () => (
  <Layout>
    <GlobalStyle />
    <Helmet titleTemplate="Thank you for reaching out · Ajmal Afif">
      <meta
        name="description"
        content={`I'll get back to you when I have the chance. You can also reach me on twitter, @ajmalafif.`}
      />
      <meta
        name="twitter:title"
        content={`Thank you for reaching out · Ajmal Afif`}
      />
      <meta
        name="twitter:description"
        content={`I'll get back to you when I have the chance. You can also reach me on twitter, @ajmalafif.`}
      />
      <meta
        property="og:title"
        content={`Thank you for reaching out · Ajmal Afif`}
      />
      <meta
        property="og:description"
        content={`I'll get back to you when I have the chance. You can also reach me on twitter, @ajmalafif.`}
      />
    </Helmet>
    <PageWrapper>
      <PageSection>
        <PageHeader>Thank you!</PageHeader>
        <p class="lh-copy">
          I'll get back to you when I have the chance. You can also reach me on
          twitter,{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="link"
            href="https://twitter.com/ajmalafif"
          >
            @ajmalafif
          </a>
          .
        </p>
        <p className="mt4">
          <ButtonSecondary role="button" to="/blog/">
            Check out my articles →
          </ButtonSecondary>
        </p>
      </PageSection>
    </PageWrapper>
  </Layout>
);